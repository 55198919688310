import React, { useEffect, useState } from "react";
import { Card, CardBody, CardExpandToggler } from "./../../../components/card/card.jsx";
import Chart from "react-apexcharts";
import { apiUrl } from "../../../config/config.js";
import { getChartOptions } from "../../../components/dashboard/getChartOptions.js";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.onmouseenter = Swal.stopTimer;
		toast.onmouseleave = Swal.resumeTimer;
	},
});

function Dashboard() {
	const [ibsQty, setIbsQty] = useState(0);
	const [lotsQty, setLotsQty] = useState(0);
	const [payQty, setPayQty] = useState(0);
	const [percentQty, setPercentQty] = useState(0);
	const [ibsData, setIbsData] = useState([]);
	const [followers, setFollowers] = useState([]);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const statsData = [
		{
			title: "IBs",
			total: "420",
			info: [
				{
					icon: "fa fa-chevron-up fa-fw me-1",
					text: "33.3% more than last week",
				},
				{
					icon: "far fa-user fa-fw me-1",
					text: "45.5% new visitors",
				},
				{
					icon: "far fa-times-circle fa-fw me-1",
					text: "3.25% bounce rate",
				},
			],
			chartData: [
				{
					name: "Visitors",
					data: [69, 34, 70, 73, 41, 32, 73, 72, 89, 57, 42, 86, 78],
				},
			],
			chartType: "bar",
			chartHeight: 30,
		},
		{
			title: "A PAGAR",
			total: "235",
			info: [
				{
					icon: "fa fa-chevron-up fa-fw me-1",
					text: "20.4% more than last week",
				},
				{
					icon: "fa fa-shopping-bag fa-fw me-1",
					text: "33.5% new orders",
				},
				{
					icon: "fa fa-dollar-sign fa-fw me-1",
					text: "6.21% conversion rate",
				},
			],
			chartData: [
				{
					name: "Sales",
					data: [81, 89, 71, 75, 53, 47, 78, 33, 71, 81, 45, 43, 68],
				},
			],
			chartType: "line",
			chartHeight: 30,
		},
		{
			title: "LOTES",
			total: "44",
			info: [
				{
					icon: "fa fa-chevron-up fa-fw me-1",
					text: "59.5% more than last week",
				},
				{
					icon: "fab fa-facebook-f fa-fw me-1",
					text: "45.5% from facebook",
				},
				{
					icon: "fab fa-youtube fa-fw me-1",
					text: "15.25% from youtube",
				},
			],
			chartData: [77, 80, 70],
			chartType: "pie",
			chartHeight: 45,
		},
		{
			title: "IBs FOLLOWERS",
			total: "44",
			info: [
				{
					icon: "fa fa-chevron-up fa-fw me-1",
					text: "33.3% more than last week",
				},
				{
					icon: "far fa-user fa-fw me-1",
					text: "45.5% new visitors",
				},
				{
					icon: "far fa-times-circle fa-fw me-1",
					text: "3.25% bounce rate",
				},
			],
			chartData: [
				{
					name: "Visitors",
					data: [69, 34, 70, 73, 41, 32, 73, 72, 89, 57, 42, 86, 78],
				},
			],
			chartType: "bar",
			chartHeight: 30,
		},
	];

	const [chartOptions, setChartOptions] = useState(getChartOptions());

	useEffect(() => {
		fetchDashboardData();
	}, []);

	const fetchDashboardData = async (startDate, endDate) => {
		try {
			const response = await fetch(apiUrl + "/getIbsData", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ startDate, endDate }),
			});
			if (response.status === 200) {
				let data = await response.json();
				console.log("IBs RESP", data);
				setIbsData(data);

				const totalLines = data.length;
				const totalIbTotal = data.reduce((sum, item) => sum + item.ibTotal, 0);
				const totalIbPay = data.reduce((sum, item) => sum + item.toPay, 0);
				const totalIbFollowers = data.reduce((sum, item) => sum + item.ibFollowers, 0);

				setIbsQty(totalLines);
				setLotsQty(totalIbTotal.toFixed(2));
				setPayQty(totalIbPay.toFixed(2));
				setFollowers(totalIbFollowers.toFixed(2));
			}
		} catch (error) {
			console.error("Error al realizar la solicitud:", error);
		}
	};

	const handleFilterSubmit = (e) => {
		e.preventDefault();
		fetchDashboardData(startDate, endDate);
	};

	const counts = {
		IBs: ibsQty,
		"A PAGAR": "$" + payQty,
		LOTES: lotsQty,
		"IBs FOLLOWERS": followers,
	};

	const markAsPayed = async (item) => {
		try {
			console.log("POITIONS", item.positions);
			const response = await fetch(apiUrl + "/markAsPayed", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ positions: item.positions }),
			});
			if (response.status === 200) {
				let data = await response.json();
				console.log("IBs RESP", data);
				fetchDashboardData(startDate, endDate);
				Toast.fire({
					icon: "error",
					title: data.message,
					background: "#1e2836",
				});
			}
		} catch (error) {
			console.error("Error al realizar la solicitud:", error);
		}
	};

	return (
		<div>
			<div className="row">
				{statsData &&
					statsData.length > 0 &&
					statsData.map((stat, index) => (
						<div className="col-xl-3 col-lg-6" key={index}>
							<Card className="mb-3">
								<CardBody>
									<div className="d-flex fw-bold small mb-3">
										<span className="flex-grow-1">{stat.title}</span>
										<CardExpandToggler />
									</div>
									<div className="row align-items-center mb-2">
										<div className="col-7">
											<h3 className="mb-0">{counts[stat.title]}</h3>
										</div>
										<div className="col-5">
											<div className="mt-n2">
												<Chart
													type={stat.chartType}
													height={stat.chartHeight}
													options={chartOptions[stat.chartType]}
													series={stat.chartData}
												/>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
					))}

				<div className="col-xl-12 col-lg-12">
					<form onSubmit={handleFilterSubmit} className="mb-4">
						<div className="row">
							<div className="col-md-4">
								<label htmlFor="startDate" className="form-label">
									Fecha de Inicio
								</label>
								<input
									type="date"
									id="startDate"
									className="form-control"
									value={startDate}
									onChange={(e) => setStartDate(e.target.value)}
								/>
							</div>
							<div className="col-md-4">
								<label htmlFor="endDate" className="form-label">
									Fecha de Fin
								</label>
								<input
									type="date"
									id="endDate"
									className="form-control"
									value={
										endDate
											? typeof endDate === "string"
												? endDate
												: endDate.toISOString().split("T")[0]
											: new Date().toISOString().split("T")[0]
									}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
							<div className="col-md-4 d-flex align-items-end">
								<button type="submit" className="btn btn-primary">
									Filtrar
								</button>
							</div>
						</div>
					</form>

					<Card className="mb-3">
						<CardBody>
							<div className="d-flex fw-bold small mb-3">
								<span className="flex-grow-1">LISTA DE IBs</span>
								<CardExpandToggler />
							</div>
							<div className="table-responsive">
								<table className="table table-striped table-borderless mb-2px small text-nowrap">
									<thead>
										<tr>
											<th>Nombre</th>
											<th>Codigo</th>
											<th>Total Lotes</th>
											<th>A Pagar</th>
											<th>Seguidores</th>
											<th>Acciones</th>
										</tr>
									</thead>
									<tbody>
										{ibsData && ibsData.length > 0 ? (
											ibsData.map((item) => (
												<tr key={item.ibCode}>
													<td>{item.ibName}</td>
													<td>{item.ibCode}</td>
													<td>{item.ibTotal.toFixed(2)}</td>
													<td>${item.toPay.toFixed(2)}</td>
													<td>{item.ibFollowers}</td>
													<td className="align-middle">
														<button
															onClick={() => markAsPayed(item)}
															className="btn btn-outline-info btn-sm mr-3"
															style={{ marginRight: "0.75rem" }}>
															<i className="fas fa-money-bill-wave"></i>
														</button>
														{/* <button onClick={() => {}} className="btn btn-outline-danger btn-sm">
															<i className="fas fa-undo-alt"></i>
														</button> */}
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan="5">No se encontraron resultados</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</CardBody>
					</Card>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;
